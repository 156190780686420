import footerConfigs from '~/config/footer';

export function getSocialMediaLinks() {
  const nuxtApp = useNuxtApp();
  const t = nuxtApp.$i18n.t;
  const locale = nuxtApp.$i18n.locale;

  const socialNetworks = {
    facebook: {
      icon: 'sn-facebook',
      attrs: {
        href: t('t_HWFACEBOOKURL'),
        title: t('t_JOINUSONFACEBOOK'),
      },
    },
    instagram: {
      icon: 'sn-instagram-circle',
      attrs: {
        href: t('t_HWINSTAGRAMURL'),
        title: t('t_HWINSTAGRAM'),
      },
    },
    twitter: {
      icon: 'sn-twitter-circle',
      attrs: {
        href: t('t_HWTWITTERURL'),
        title: t('t_FOLLOWUSONTWITTER'),
      },
    },
    youtube: {
      icon: 'sn-youtube-circle',
      attrs: {
        href: t('t_HWYOUTUBEURL'),
        title: t('t_WATCHUSONYOUTUBE'),
      },
    },
    pinterest: {
      icon: 'sn-pinterest',
      attrs: {
        href: t('t_HWPINTERESTURL'),
        title: t('t_HWPINTERESTTITLE'),
      },
    },
    tiktok: {
      icon: 'sn-tiktok-circle',
      attrs: {
        href: t('t_HWTIKTOKURL'),
        title: t('t_HWTIKTOKTITLE'),
      },
    },
    weibo: {
      icon: 'sn-weibo',
      attrs: {
        href: t('t_HWWEIBOURL'),
        title: t('t_JOINUSONWEIBO'),
      },
    },
    naver: {
      icon: 'sn-naver',
      attrs: {
        href: t('t_HWNAVERURL'),
        title: t('t_NAVERBLOG'),
      },
    },
    naverblog: {
      icon: 'sn-naver-post',
      attrs: {
        href: t('t_HWNAVERPOSTURL'),
        title: t('t_NAVERBLOG'),
      },
    },
  };

  // @TODO: Caching this might improve performance
  const getSocialNetworks = () => {
    const exportedSocialNetworks = [];
    footerConfigs.socialNetworks[locale?.value]?.forEach((snLabel) => {
      exportedSocialNetworks.push(socialNetworks[snLabel]);
    });
    return exportedSocialNetworks;
  };

  return { getSocialNetworks: getSocialNetworks() };
}
