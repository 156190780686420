<script setup>
import { WdsFooter } from '@wds/components';
import { getLegalLinks } from './sections/legal-links';
import { getSocialMediaLinks } from './sections/social-media-links';
import { getCommonLinks } from './sections/quick-links';
import { useCurrencyStore } from '~/stores/currency';
import { useLanguageStore } from '~/stores/language';

const props = defineProps({
  isShortVersion: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const languageStore = useLanguageStore();
const currencyStore = useCurrencyStore();
const { t } = useI18n();
const { useUrl } = useUrls();

const quickLinks = getCommonLinks({ useUrl });
const socialMediaLinks = getSocialMediaLinks();

const wdsFooterRef = ref(null);

const { isShortVersion } = toRefs(props);

const getFooterData = computed(() => {
  return {
    isShortVersion: isShortVersion?.value,
    currentLanguage: languageStore?.getCurrentLanguage?.name,
    currentCurrency: currencyStore?.getCurrentCurrency?.code,
    socialMediaLinks: socialMediaLinks?.getSocialNetworks,
    quickLinks: quickLinks?.getQuickLinks,
    legalLinks: getLegalLinks({ useUrl }),
    cncLogo: { show: true },
    translations: { tFollowUs: t('t_FOLLOWUSON') },
  };
});

onMounted(() => {
  const legalLinks = wdsFooterRef?.value?.$el?.querySelector('.footer-area.legal-links');
  if (legalLinks) {
    legalLinks.insertAdjacentHTML('beforeend', '<div id="teconsent">');
    legalLinks.setAttribute('id', 'footerLegalLinks');
  }
});
</script>

<template>
  <WdsFooter
    ref="wdsFooterRef"
    class="footer-web"
    v-bind="getFooterData"
    @language-picker-click="languageStore.toggleLanguagePicker(true)"
    @currency-picker-click="currencyStore.toggleCurrencyPicker(true)"
  />
</template>

<style lang="scss" scoped>
.footer-web {
  padding-bottom: wds-rem(56px);

  @include desktop {
    padding-bottom: 0;
  }
}
</style>

<style lang="scss">
/* stylelint-disable-next-line selector-class-pattern */
.truste_cursor_pointer {
  color: $wds-color-white;
  display: inline-flex;
  align-items: center;
  min-height: $wds-spacing-xxl;
  min-width: $wds-spacing-xl;
  padding: 0 $wds-spacing-xs;

  &:hover {
    text-decoration: none;
    color: $wds-color-white;
  }
}

#teconsent a {
  color: $wds-color-white;
}
</style>
